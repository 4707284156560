<template>
  <div class="app-install-success">
    <div class="congratulations">Loading...</div>
  </div>
</template>
<script>
import Client, { Redirect, shared } from '@shoplinedev/appbridge';

import { getRedirectUrl, installPaymentAppActive } from '@/api/plugin';
export default {
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
  },
  mounted() {
    const { appName, appkey } = this.$route.query;
    const tryGetRedirectUrl = async () => {
      try {
        const res = await getRedirectUrl('shopline', appName, this.$route.query);
        if (res.respCode === '20000') {
          if (res.data) {
            setTimeout(() => {
              this.active(appName, this.$route.query);
              const app = Client.createApp({
                appKey: appkey,
                host: shared.getHost(),
              });
              const redirect = Redirect.create(app);
              redirect.replaceTo(res.data);
              // this.$router.push({
              //   path: '/v1/plugin/appInstall/shopline/iframe-active',
              //   query: { url: res.data, appName, data: this.$route.query },
              // });
            }, 3000);
          }
        }
      } catch (error) {
      // 如果请求失败，等待 3 秒后重新请求
        console.error('请求失败，3秒后重试...', error);
        setTimeout(tryGetRedirectUrl, 3000);
      }
    };

    tryGetRedirectUrl();
  },
  methods: {
    active(appName, data) {
      installPaymentAppActive('shopline', appName, data).then((res) => {

      }).catch(() => {

      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-install-success {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 130px;
  .success {
    width: 134px;
  }
  .congratulations {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #212B36;
    line-height: 28px;
    margin-top: 28px;
  }
  .tips {
    font-size: 18px;
    color: #616D7A;
    line-height: 21px;
    margin-top: 20px;
    text-align: left;
  }
}
</style>
